import { getNationItemByCountryCode } from '@pixels/universal/model/nation/nation.model';
import { PhoneValue } from '@pixels/universal/model/phone-number.model';
import { type CountryCode, parsePhoneNumberWithError } from 'libphonenumber-js';

export function extractPhoneNumber(phoneValue: PhoneValue): string {
  return `${phoneValue.nationalNumber}${phoneValue.phoneNumber.replace(/^(0|820)/, '')}`;
}

export const PHONE_NUMBER_MAX_LENGTH = 11;

export function formatPhoneNumber(phoneNumber: string): string {
  try {
    const phoneNumberObj = parsePhoneNumberWithError(phoneNumber);
    const phoneCountry = phoneNumberObj.country as CountryCode;
    const nationNumber = getNationItemByCountryCode().get(phoneCountry)?.nationNumber;
    const formatStr = phoneNumberObj.formatInternational();
    if (nationNumber) {
      const originNationNumber = `+${phoneNumberObj.countryCallingCode}`;
      const restNationNumber = nationNumber.replace(originNationNumber, '');
      if (restNationNumber) {
        return nationNumber + ' ' + formatStr.replace(new RegExp('\\' + originNationNumber + ' '), '')
          .replace(restNationNumber, '')
          .trim();
      }
    }
    return formatStr;
  } catch (e) {
    return phoneNumber;
  }
}

export function maskPhoneNumber(phoneNumber: string): string {
  return phoneNumber.replace(/ (\d){4}$/, ' ****');
}
